import styled from "styled-components";
import { MainContainer } from "../MainContainer/MainContainer";
import panther from '../../images/animatedBg.mp4'
import CountdownComponent from "../CountDownComponent/CountdownComponent";
import Reveal from "../AnimationComponents/Reveal";
import HorizontalScroll from "../HorizontalScroll/HorizontalScroll";

const IntroSectionDiv = styled.div`
display:flex;
flex-direction:column;
justify-content:space-between;
align-items:center;
height:100vh;
padding-top:100px;
//background-image:url(${panther});
//background-size:cover;
//background-position:center;
width:100%;
position:sticky;
top:0px;
z-index:50;

@media screen and (max-width:960px){
    padding-bottom:0px;
    position:relative;
}
`;

const IntroSectionTitleDiv = styled.div`
gap:30px;
display:flex;
flex-direction:column;
justify-content:start;
z-index:101;
max-width:750px;
align-items:start;

@media screen and (max-width:960px){
    max-width:100%;
    align-items:center;
}
`;

const IntroSectionTitle = styled.p`
color:white;
font-weight:500;
font-size:65px;

@media screen and (max-width:960px){
    font-size:40px;
}
`;

const IntroSectionSubtitle = styled.p`
font-size:20px;
font-weight:400;
color:white;
max-width:600px;

@media screen and (max-width:960px){
    font-size:20px;
}
`;

export const IntroSectionImg = styled.img`
width:100%;
position:absolute;
top:0px;
right:0px;
z-index:-1;
opacity:100%;

@media screen and (max-width:960px){
    justify-content:center;
    display:flex;
    width:200%;
}
`;

const WrapperButton = styled.div`
padding:2px;
border-radius:60px;
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
`;

const IntroButton = styled.button`
padding:30px;
max-width:600px;
background:black;
border-radius:60px;
border:none;
color:white;
font-size:24px;
font-weight:600;
cursor:pointer;
transition:0.2s;

&:active{
    transform:scale(0.93);
 }

&:hover{
    background:rgb(0, 0, 0, 0.5);
    transition: 0.2s;
    color:white;
}

@media screen and (max-width:960px){
    width:100%;
    font-size:14px;
}
`;

const IntroContainer = styled.div`
display:flex;
justify-content:space-between;
flex-direction:row;
width:100%;

@media screen and (max-width:960px){
    flex-direction:column;
    align-items:center;
}
`;

const IntroBgImg = styled.img`
width:100%;
position:absolute;
top:-600px;
left:0px;
overflow:hidden;
filter: contrast(70%);
opacity:80%;
`;

const IntroButtonDiv = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:40px;
`;


const IntroSection = () => {
    const bgObj = {
        'background': '#9BF3A4',
        'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        'fontWeight': '700',
    }

    return (
        <IntroSectionDiv>
            <MainContainer style={{ width: '100%', alignItems: 'start', justifyContent: 'start' }}>
                <IntroContainer>
                    <IntroSectionTitleDiv>
                        <Reveal>
                            <IntroSectionTitle><span style={bgObj}>TRADE</span> CRYPTO ASSETS</IntroSectionTitle>
                        </Reveal>
                        <Reveal>
                            <IntroSectionSubtitle>WITH OUR CONFIGURABLE STRATEGIES AND GET GUIDANCE FROM OUR CONVERSATIONAL AI AGENTS, ALL THROUGH YOUR MESSAGING APP</IntroSectionSubtitle>
                        </Reveal>
                        <IntroButtonDiv>
                            <Reveal>
                                <a target='_blank' href='/' style={{ textDecoration: 'none', color: 'black' }}><WrapperButton><IntroButton>JOIN US AND ACCESS BETA FOR FREE</IntroButton></WrapperButton></a>
                            </Reveal>

                        </IntroButtonDiv>
                    </IntroSectionTitleDiv>

                </IntroContainer>
            </MainContainer>
            <HorizontalScroll />
            <video autoPlay muted loop id="myVideo" style={{position:'absolute', right:0, bottom:'-90px', minWidth:'100%', minHeight:'100%', zIndex:-1}}>
                <source src={panther} type="video/mp4" />
            </video>
        </IntroSectionDiv>
    )
}

export default IntroSection;