import styled from "styled-components";
import { FaTwitter } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import panther from '../../images/panther.png'
import { useNavigate } from 'react-router-dom';

const FooterDiv = styled.div`
display:flex;
width:100%;
flex-direction:column;
`;

const FirstDiv = styled.div`
background: rgb(8,48,48);
background: radial-gradient(circle, rgba(8,48,48,1) 0%, rgba(7,45,48,1) 29%, rgba(7,37,45,1) 71%, rgba(7,27,38,1) 100%);
padding:80px 20px 80px 20px;
width:100%;
display:flex;
justify-content:center;
align-items:center;
`;

const FirstDivWrapper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
width:50%;

@media screen and (max-width:960px){
flex-direction:column;
gap:50px;
width:auto;
align-items:start;
}
`;

const SecondDiv = styled.div`
height:50px;
width:100%;
display:flex;
justify-content:center;
align-items:center;
padding:80px 40px 80px 40px;
`;

const SecondDivWrapper = styled.div`
display:flex;
align-items:center;
justify-content:space-between;
width:50%;

@media screen and (max-width:960px){
width:100%;
gap:40px;
}
`;

const TitleDiv = styled.div`
display:flex;
justify-content:center;
align-items:center;
gap:10px;
flex-direction:column;
`;

const TitleText = styled.div`
color:white;
font-weight:400;
font-size:28px;
`;

const SocialMediaDiv = styled.div`
display:flex;
gap:20px;
`;

const PageSectionsDiv = styled.div`
display:flex;
gap:120px;

@media screen and (max-width:960px){
gap:60px;
}
`;

const PageSectionItem = styled.div`
display:flex;
flex-direction:column;
gap:10px;
`;

const PageSectionTitle = styled.div`
font-weight:500;
font-size:18px;
color:white;
`;

const PageSectionText = styled.div`
color:white;
font-size:14px;
cursor:pointer;
`;

const CustomLogo = styled.div`
color:white;
font-size:16px;
cursor:pointer;
`;

const SecondTitle = styled.p`
font-size:14px;
color:white;
`;

const SecondLogoDiv = styled.div`
display:flex;
gap:40px;
max-height:60px;
justify-content:center;
align-items:center;

@media screen and (max-width:960px){
flex-direction:column;
}
`;

const SecondLogoImg = styled.img`
width:120px;
`;

const Footer = () => {
    const navigate = useNavigate();

    const bgObj = {
        'background': '#9BF3A4',
        'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        'fontWeight': '700',
    }

    return (
        <FooterDiv>
            <FirstDiv>
                <FirstDivWrapper>
                    <TitleDiv>
                        <TitleText><span style={bgObj}>WAYOB</span>LABS.</TitleText>
                        <SocialMediaDiv>
                            <a target='_blank' href=''><CustomLogo><FaTwitter /></CustomLogo></a>
                            <a href=''><CustomLogo><FaTelegramPlane /></CustomLogo></a>
                        </SocialMediaDiv>
                    </TitleDiv>
                    <PageSectionsDiv>
                        <PageSectionItem>
                            <PageSectionTitle>WAYOB LABS</PageSectionTitle>
                            <PageSectionText>Wayob Token</PageSectionText>
                        </PageSectionItem>
                        <PageSectionItem>
                            <PageSectionTitle>RESOURCES</PageSectionTitle>
                            <PageSectionText>FAQ</PageSectionText>
                            <PageSectionText>Privacy</PageSectionText>
                            <PageSectionText>Terms & Conditions</PageSectionText>
                        </PageSectionItem>
                    </PageSectionsDiv>
                </FirstDivWrapper>
            </FirstDiv>
            <SecondDiv>
                <SecondDivWrapper>
                    <SecondTitle>Wayob Labs © 2024</SecondTitle>
                    <SecondLogoDiv>
                        <SecondLogoImg style={{height:'25px'}} src='https://cdn.prod.website-files.com/5efccc15b40a7dfbb529ea1a/6018074d8f41d9f6d804bfc7_trustedsite_logo.png' />
                        <SecondLogoImg src='https://imagedelivery.net/KxWh-mxPGDbsqJB3c5_fmA/58f1b522-250f-4ecd-1086-aa2f972be700/public' />
                    </SecondLogoDiv>
                </SecondDivWrapper>
            </SecondDiv>
        </FooterDiv>
    );
}

export default Footer;

/*
<PageItem>
                        <PageItemTitle>PRODUCTS</PageItemTitle>
                        <PageSubItem onClick={() => navigate('/indicator')}>TELEGRAM BOT</PageSubItem>
                        <PageSubItem onClick={() => navigate('/indicator')}>POOLS INDICATOR</PageSubItem>
                    </PageItem>
*/