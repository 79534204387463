import styled from "styled-components";
import { MdCalendarMonth } from "react-icons/md";
import { MainContainer } from "../MainContainer/MainContainer";
import { ImLab } from "react-icons/im";
import { FaHandMiddleFinger, FaRobot } from "react-icons/fa";
import { FaNewspaper, FaRotate, FaScaleBalanced } from "react-icons/fa6";
import { AiFillControl } from "react-icons/ai";
import strategyLab from '../../images/strategyLab.png';
import controlCenter from '../../images/controlCenter.png';
import newsFeed from '../../images/newsFeed.png';
import Reveal from "../AnimationComponents/Reveal";
import { motion } from "framer-motion";
import { mainColors } from "../../utils/globalObjects";
import { IoIosArrowRoundForward } from "react-icons/io";

const itemAnimate = {
    initial: {
        y: 120,
        opacity: 0,    
    },
    animate: {
        y: 0,
        opacity: 1,
    },
    exit: {},
}

const exploreAnimate = {
    initial: {
        transform:'scaleX(0)',
        transformOrigin:'right',
        opacity: 0,
        transition: { delay:0.50 },
    },
    animate: {
        transform:'scaleX(1)',
        transformOrigin:'right',
        opacity: 1,
        transition: { delay:0.50 }
    },
    exit: {}
}

const AdvantagesSectionDiv = styled.div`
width:100%;
display:flex;
justify-content:center;
flex-direction:column;
align-items:center;
background:background: rgb(8,54,63);
background: linear-gradient(90deg, rgba(8,54,63,1) 1%, rgba(2,27,31,1) 71%, rgba(0,23,23,1) 100%);
z-index:100;
position:relative;
`;

const AdvantagesList = styled(motion.div)`
display:flex;
padding:20px;
justify-content:center;
max-width:100%;
gap:20px;
flex-wrap:wrap;
overflow:hidden;

@media screen and (max-width:960px){
    gap:20px;
    padding:0px;
}
`;


const AdvantagesSectionTitleDiv = styled.div`
max-width:900px;
display:flex;
flex-direction:column;
gap:10px;

@media screen and (max-width:960px){
max-width:100%; 
}
`;

const AdvantagesSectionTitle = styled.p`
font-size:60px;
font-weight:500;
color:white;

@media screen and (max-width:960px){
    font-size:40px;
}
`;

const AdvantagesSectionSubtitle = styled.p`
font-size:20px;
font-weight:400;
color:white;

@media screen and (max-width:960px){
    font-size:20px;   
}
`;

const ExploreText = styled.p`
color:white;
font-size:17px;
`;

const ExploreTextDiv = styled.div`
display:flex;
align-items:center;
justify-content:center;
gap:5px;
transition:0.2s;
padding:0px 40px 0px 40px;
`;

const ArrowIcon = styled(IoIosArrowRoundForward)`
color:white;
font-size:30px;
`;

const ExploreDiv = styled(motion.div)`
width:100%;
align-items:center;
justify-content:space-between;
border-top:1px solid ${mainColors.boxBorder};
display:flex;
height:100%;;
background: rgb(18,19,21);
background: linear-gradient(90deg, rgba(18,19,21,1) 0%, rgba(17,31,30,1) 46%, rgba(16,38,30,1) 100%);
border-bottom-right-radius:10px;
border-bottom-left-radius:10px;
padding-left:40px;
`;

const AdvantageItemTitleDiv = styled(motion.div)`
display:flex;
flex-direction:column;
gap:20px;
max-width:340px;
padding:40px;
height:400px;
`;

const AdvantageItemTitle = styled.p`
font-size:24px;
color:${mainColors.greenColor}
`;

const AdvantageItemText = styled.p`
color:white;
font-size:19px;
`;

const DetailDiv = styled.div`
padding:10px;
color:white;
font-size:12px;
border:1px solid ${mainColors.greenColor};
background:${mainColors.greenColor2};
border-radius:30px;
`;

const AdvantageItem = styled(motion.a)`
display:flex;
flex-direction:column;
width:500px;
background:#0f0f0f;
height:300px;
border-radius:10px;
cursor:pointer;
//transition:0.2s;
text-decoration:none;

&:hover{
    filter:brightness(1.25);
    transition:0.2s;
    ${ExploreTextDiv}{
        padding:0px 30px 0px 20px;
        gap:15px;
    }
}

@media screen and (max-width:1800px){
    width:400px;
    height:260px;

    ${AdvantageItemText}{
        font-size:16px;
    }

    ${AdvantageItemTitleDiv}{
        padding:30px;
    }
}
`;

const AdvantagesSection = () => {
    const bgObj = {
        'background': '#9BF3A4',
        'background': 'linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%)',
        '-webkit-background-clip': 'text',
        '-webkit-text-fill-color': 'transparent',
        'fontWeight': '700',
    }

    return (
        <AdvantagesSectionDiv>
            <MainContainer style={{ flexDirection: 'column', justifyContent: 'start', gap: '60px', width: '100%' }}>
                <AdvantagesSectionTitleDiv>
                    <Reveal>
                        <AdvantagesSectionTitle><span style={bgObj}>AI</span> TRADING TOOLS</AdvantagesSectionTitle>
                    </Reveal>
                    <Reveal>
                        <AdvantagesSectionSubtitle>Our collection includes a diverse range of AI tools designed to keep you updated, offer personalized suggestions and facilitate secure trading.</AdvantagesSectionSubtitle>
                    </Reveal>
                </AdvantagesSectionTitleDiv>
                <AdvantagesList>
                    <AdvantageItem target='_blank' href='/' variants={itemAnimate} initial='initial' whileInView='animate' exit='exit' transition={{delay:0.35, duration:0.5}}>
                        <AdvantageItemTitleDiv>
                            <Reveal>
                                <AdvantageItemTitle>Wayob AI Bot</AdvantageItemTitle>
                            </Reveal>
                            <Reveal>
                                <AdvantageItemText>A tech-savvy AI bot is here to provide support and quick access to crypto investments.</AdvantageItemText>
                            </Reveal>
                        </AdvantageItemTitleDiv>
                        <ExploreDiv variants={exploreAnimate} initial='initial' whileInView='animate' exit='exit'>
                            <DetailDiv>Signals and Automatic</DetailDiv>
                            <ExploreTextDiv><ExploreText>Explore</ExploreText><ArrowIcon /></ExploreTextDiv>
                        </ExploreDiv>
                    </AdvantageItem>
                    <AdvantageItem target='_blank' href='https://app.wayoblabs.com/pools/btc' variants={itemAnimate} initial='initial' whileInView='animate' exit='exit' transition={{delay:0.45, duration:0.5}}>
                        <AdvantageItemTitleDiv>
                            <Reveal>
                                <AdvantageItemTitle>Liquidity Pools Lab</AdvantageItemTitle>
                            </Reveal>
                            <Reveal>
                                <AdvantageItemText>A lab that provides data analysis and macro trade strategies.</AdvantageItemText>
                            </Reveal>
                        </AdvantageItemTitleDiv>
                        <ExploreDiv variants={exploreAnimate} initial='initial' whileInView='animate' exit='exit' style={{ justifyContent: 'end' }}>
                            <ExploreTextDiv><ExploreText>Explore</ExploreText><ArrowIcon /></ExploreTextDiv>
                        </ExploreDiv>
                    </AdvantageItem>
                    <AdvantageItem target='_blank' href='https://app.wayoblabs.com/controlcenter' variants={itemAnimate} initial='initial' whileInView='animate' exit='exit' transition={{delay:0.55, duration:0.5}}>
                        <AdvantageItemTitleDiv>
                            <Reveal>
                                <AdvantageItemTitle>Control Center</AdvantageItemTitle>
                            </Reveal>
                            <Reveal>
                                <AdvantageItemText>The Control Center is a hub to visualize and manage crypto assets.</AdvantageItemText>
                            </Reveal>
                        </AdvantageItemTitleDiv>
                        <ExploreDiv variants={exploreAnimate} initial='initial' whileInView='animate' exit='exit' style={{ justifyContent: 'end' }}>
                            <ExploreTextDiv><ExploreText>Explore</ExploreText><ArrowIcon /></ExploreTextDiv>
                        </ExploreDiv>
                    </AdvantageItem>
                    <AdvantageItem target='_blank' href='https://app.wayoblabs.com/copymarketplace' variants={itemAnimate} initial='initial' whileInView='animate' exit='exit' transition={{delay:0.65, duration:0.5}}>
                        <AdvantageItemTitleDiv>
                            <Reveal>
                                <AdvantageItemTitle>Copy Marketplace</AdvantageItemTitle>
                            </Reveal>
                            <Reveal>
                                <AdvantageItemText>An entire marketplace of strategy bots just for you to copy.</AdvantageItemText>
                            </Reveal>
                        </AdvantageItemTitleDiv>
                        <ExploreDiv variants={exploreAnimate} initial='initial' whileInView='animate' exit='exit'>
                            <DetailDiv>Automatic trading</DetailDiv>
                            <ExploreTextDiv><ExploreText>Explore</ExploreText><ArrowIcon /></ExploreTextDiv>
                        </ExploreDiv>
                    </AdvantageItem>
                    <AdvantageItem target='_blank' href='https://app.wayoblabs.com/backtest' variants={itemAnimate} initial='initial' whileInView='animate' exit='exit' transition={{delay:0.75, duration:0.5}}>
                        <AdvantageItemTitleDiv>
                            <Reveal>
                                <AdvantageItemTitle>Backtest Bot</AdvantageItemTitle>
                            </Reveal>
                            <Reveal>
                                <AdvantageItemText>Test the effectiveness of your trading strategies with our backtesting tool.</AdvantageItemText>
                            </Reveal>
                        </AdvantageItemTitleDiv>
                        <ExploreDiv variants={exploreAnimate} initial='initial' whileInView='animate' exit='exit'>
                            <DetailDiv>Fast and accurate</DetailDiv>
                            <ExploreTextDiv><ExploreText>Explore</ExploreText><ArrowIcon /></ExploreTextDiv>
                        </ExploreDiv>
                    </AdvantageItem>
                    <AdvantageItem target='_blank' href='/' variants={itemAnimate} initial='initial' whileInView='animate' exit='exit' transition={{delay:0.85, duration:0.5}}>
                        <AdvantageItemTitleDiv>
                            <Reveal>
                                <AdvantageItemTitle>News Feed</AdvantageItemTitle>
                            </Reveal>
                            <Reveal>
                                <AdvantageItemText>Stay informed with precise time data, recent trends and insights.</AdvantageItemText>
                            </Reveal>
                        </AdvantageItemTitleDiv>
                        <ExploreDiv variants={exploreAnimate} initial='initial' whileInView='animate' exit='exit'>
                            <DetailDiv>Real-Time</DetailDiv>
                            <ExploreTextDiv><ExploreText>Explore</ExploreText><ArrowIcon /></ExploreTextDiv>
                        </ExploreDiv>
                    </AdvantageItem>
                </AdvantagesList>
            </MainContainer>
        </AdvantagesSectionDiv>
    );
}

export default AdvantagesSection;

/*
<AdvantageItem>
                        <AdvantageImgDiv  variants={imgDivAnimate} initial='initial' whileInView='animate' exit='exit'>
                            <AdvantageImg  variants={imgAnimate} initial='initial' whileInView='animate' src={newsFeed} exit='exit'/>
                        </AdvantageImgDiv>
                        <AdvantageTitleDiv>
                                <AdvantageTitle viewport={{amount:0.8}} variants={textAnimate} initial='initial' whileInView='animate' exit='exit'>NEWS FEED</AdvantageTitle>
                                <AdvantageText viewport={{amount:0.8}} variants={textAnimate} initial='initial' whileInView='animate' exit='exit'>News Feed is real-time information, up to date, with the latest trends and insights.</AdvantageText>
                        </AdvantageTitleDiv>
                    </AdvantageItem>
*/