import styled from "styled-components";
import { mainColors } from "../../utils/globalObjects";
import strategyLab from '../../images/strategyLab.png'
import Reveal from "../AnimationComponents/Reveal";
import { motion } from "framer-motion";

const cardAnimate = {
    initial: {
        transform:'scaleX(0)',
        opacity: 0,
        transformOrigin:'right',
        transition: { duration: 0.5, delay:0.25, ease:'easeOut' },
    },
    animate: {
        transform:'scaleX(1)',
        opacity: 1,
        transformOrigin:'right',
        transition: { duration: 0.5, delay:0.25, ease:'easeOut' }
    },
    exit: {}
}

const imgAnimate = {
    initial: {
        y: 400,
        opacity: 0,
        transition: { duration: 0.5, delay:0.50, ease:'easeOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: { duration: 0.5, delay:0.50, ease:'easeOut' }
    },
    exit: {}
}

const titleAnimate = {
    initial: {
        x: 50,
        opacity: 0,
        transition: { duration: 0.3, delay:0.80, ease:'easeOut' },
    },
    animate: {
        x: 0,
        opacity: 1,
        transition: { duration: 0.3, delay:0.80, ease:'easeOut' }
    },
    exit: {}
}

const textAnimate = {
    initial: {
        y: 30,
        opacity: 0,
        transition: { duration: 0.3, delay:0.8, ease:'easeOut' },
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: { duration: 0.3, delay:0.8, ease:'easeOut' }
    },
    exit: {}
}

const ProductList = styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
gap:200px;
padding-top:80px;
padding-bottom:80px;
overflow:hidden;
`;

const ProductItem = styled.div`
display:flex;
gap:140px;
justify-content:center;
align-items:center;
flex-direction:${({direction}) => direction != undefined ? direction : 'row'};
`;

const ProductCardDiv = styled(motion.div)`
height:560px;
background:${mainColors.greenColor2};
border-radius:40px;
width:630px;
position:relative;
overflow:hidden;
`;

const ProductCardImg = styled(motion.img)`
position:absolute;
bottom:0px;
right:-120px;
max-width:680px;
border-top-left-radius:40px;
`;

const ProductItemTitleDiv = styled.div`
display:flex;
flex-direction:column;
gap:50px;
max-width:600px;
`;

const ProductItemTitle = styled(motion.p)`
color:white;
font-size:80px;
font-weight:700;
`;

const ProductItemText = styled(motion.p)`
color:#b7b5ba;
font-size:30px;
font-weight:500;
`;

const WrapperButton = styled(motion.div)`
padding:2px;
border-radius:60px;
background: #9BF3A4;
background: linear-gradient(to right, #9BF3A4 0%, #5CE4B4 45%, #14BAB9 100%);
width:50%;
`;

const ProductItemButton = styled.button`
padding:20px;
width:100%;
background:black;
border-radius:60px;
border:none;
color:white;
font-size:24px;
font-weight:600;
cursor:pointer;
transition:0.2s;

&:active{
    transform:scale(0.93);
 }

&:hover{
    ${WrapperButton}{
        width:55%;
    }

    background:rgb(0, 0, 0, 0.5);
    transition: 0.2s;
    color:white;
}

@media screen and (max-width:960px){
    width:100%;
    font-size:14px;
}
`;

const ProductsSection = () => {
    return (
        <ProductList>
            <ProductItem>
                <ProductCardDiv variants={cardAnimate} initial='initial' whileInView='animate' exit='exit'>
                    <ProductCardImg variants={imgAnimate} initial='initial' whileInView='animate' exit='exit' src={strategyLab}/>
                </ProductCardDiv>
                <ProductItemTitleDiv>
                    <Reveal>
                    <ProductItemTitle variants={titleAnimate} initial='initial' whileInView='animate' exit='exit'>Receive AI Signals</ProductItemTitle>
                    </Reveal>
                    <Reveal>
                    <ProductItemText variants={textAnimate} initial='initial' whileInView='animate' exit='exit'>Customize and receive signals by our AI assistant in telegram.</ProductItemText>
                    </Reveal>
                    <Reveal>
                    <WrapperButton variants={textAnimate} initial='initial' whileInView='animate' exit='exit'>
                        <ProductItemButton>Receive signals</ProductItemButton>
                    </WrapperButton>
                    </Reveal>
                </ProductItemTitleDiv>
            </ProductItem>
            <ProductItem direction={'row-reverse'}>
                <ProductCardDiv variants={cardAnimate} initial='initial' whileInView='animate' exit='exit'>
                    <ProductCardImg variants={imgAnimate} initial='initial' whileInView='animate' exit='exit' src={strategyLab}/>
                </ProductCardDiv>
                <ProductItemTitleDiv>
                    <Reveal>
                    <ProductItemTitle variants={titleAnimate} initial='initial' whileInView='animate' exit='exit'>Receive AI Signals</ProductItemTitle>
                    </Reveal>
                    <Reveal>
                    <ProductItemText variants={textAnimate} initial='initial' whileInView='animate' exit='exit'>Customize and receive signals by our AI assistant in telegram.</ProductItemText>
                    </Reveal>
                    <Reveal>
                    <WrapperButton variants={textAnimate} initial='initial' whileInView='animate' exit='exit'>
                        <ProductItemButton>Receive signals</ProductItemButton>
                    </WrapperButton>
                    </Reveal>
                </ProductItemTitleDiv>
            </ProductItem>
        </ProductList>
    )
}

export default ProductsSection;