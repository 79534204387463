import { styled, keyframes } from "styled-components";
import {mainColors} from '../../utils/globalObjects';
import Reveal from "../AnimationComponents/Reveal";
import { motion } from "framer-motion";

const divAnimate = {
    initial: {
        y: 50,
        opacity: 0,
        transition: { duration: 0.4, delay:0.25 }
    },
    animate: {
        y: 0,
        opacity: 1,
        transition: { duration: 0.4, delay:0.25}
    },
    exit: {}
}

const slideAnimation = keyframes`
from {
    transform:translateX(0);
}
to {
    transform: translateX(-100%);
}
`;

const HorizontalScrollDiv = styled(motion.div)`
color:white;
display:flex;
width:100%;
justify-content:center;
align-items:center;
flex-direction:row;
padding:0px;
gap:20px;
overflow:hidden;
background-color: rgba(0, 0, 0, 0.61);
backdrop-filter: blur(5px);
border-top:1px solid rgb(77, 234, 207, 0.4);
`;

const MainText = styled.p`
font-size:25px;
font-weight:500;
color:rgb(255, 255, 255, 0.4);
`;

const LogoContainer = styled.div`
white-space: nowrap;
animation: 24s ${slideAnimation} infinite linear;
display:flex;
align-items:center;
`;

const ScrollContainer = styled.div`
white-space: nowrap;
position: relative;
display:flex;
cursor:pointer;
width:100%;
`;

const LogoImg = styled.img`
width:120px;
filter: contrast(10%);
margin: 0 40px;
transition:0.3s;

&:hover{
    transform:scale(1.24);
    transition:0.3s;
}
`;

const HorizontalScroll = () => {
    return (
        <HorizontalScrollDiv variants={divAnimate} initial='initial' whileInView='animate' exit='exit'>
            <ScrollContainer>
                <LogoContainer>
                    <LogoImg style={{width:'90px'}} src='https://cdn.worldvectorlogo.com/logos/okx-1.svg' />
                    <LogoImg src='https://cdn.icon-icons.com/icons2/2699/PNG/512/microsoft_azure_logo_icon_168977.png' />
                    <LogoImg src='https://wordpress.deeplearning.ai/wp-content/uploads/2023/10/LangChain-logo.png' />
                    <LogoImg src='https://cdn.worldvectorlogo.com/logos/coingecko-1.svg' />
                    <LogoImg src='https://primary.jwwb.nl/public/m/x/t/temp-grcpvtyhkonvldsdpkip/6ek6fr/geckoterminal.webp' />
                </LogoContainer>

                <LogoContainer>
                    <LogoImg style={{width:'90px'}} src='https://cdn.worldvectorlogo.com/logos/okx-1.svg' />
                    <LogoImg src='https://cdn.icon-icons.com/icons2/2699/PNG/512/microsoft_azure_logo_icon_168977.png' />
                    <LogoImg src='https://wordpress.deeplearning.ai/wp-content/uploads/2023/10/LangChain-logo.png' />
                    <LogoImg src='https://cdn.worldvectorlogo.com/logos/coingecko-1.svg' />
                    <LogoImg src='https://primary.jwwb.nl/public/m/x/t/temp-grcpvtyhkonvldsdpkip/6ek6fr/geckoterminal.webp' />
                </LogoContainer>

                <LogoContainer>
                    <LogoImg style={{width:'90px'}} src='https://cdn.worldvectorlogo.com/logos/okx-1.svg' />
                    <LogoImg src='https://cdn.icon-icons.com/icons2/2699/PNG/512/microsoft_azure_logo_icon_168977.png' />
                    <LogoImg src='https://wordpress.deeplearning.ai/wp-content/uploads/2023/10/LangChain-logo.png' />
                    <LogoImg src='https://cdn.worldvectorlogo.com/logos/coingecko-1.svg' />
                    <LogoImg src='https://primary.jwwb.nl/public/m/x/t/temp-grcpvtyhkonvldsdpkip/6ek6fr/geckoterminal.webp' />
                </LogoContainer>
            </ScrollContainer>
        </HorizontalScrollDiv>
    );
}

export default HorizontalScroll;

//<MainText>WAYOB LABS TECHNOLOGIES ARE SUPPORTED BY</MainText>